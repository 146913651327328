.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container {
    min-height: calc(100vh - 63px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transform: translateY(-24px);
}

.gridRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.gridItem {
    flex-basis: 0;
}

@media screen and (max-width: 990px) {
    .container {
        width: 100vw;
        flex-direction: column;
    }
    .overlay {
        position: relative;
        margin: 1rem 0;
    }
    .grid {
        justify-content: center;
    }
    .gridRow {
        flex-direction: column;
        justify-content: center;
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.smuLink {
    text-decoration: none;
    color: white;
}

.smuLink:hover {
    text-decoration: underline;
}

.headerSmall {
	color: #FFFFFF;
	font-family: futura-pt, sans-serif;
	font-size: 32px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 41px;
    text-transform: uppercase;
}

.headerPara {
	color: #FFFFFF;
	font-family: futura-pt, sans-serif;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
    line-height: 22px;
    max-width: 400px;
}

.headerBig {
	color: #FFFFFF;
	font-family: duos-paint, sans-serif;
    font-weight: 900;
    font-style: normal;
	font-size: 5rem;
    line-height: 90px;
    margin: 20px 0;
}

@media screen and (max-width: 990px) {
    .header {
        margin-right: 0;
    }

    .headerSmall {
        font-size: 18px;
    }

    .headerBig {
        font-size: 56px;
        margin: 0 0;
    }

    .headerPara {
        margin-bottom: 36px;
    }
}