body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #8199F5;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.waveComponent {
    position: relative;
}

.waveComponent canvas {
    width: 15vh;
    height: 15vh;
    cursor: pointer;
}

.waveComponent[data-star="true"] canvas {
    width: 100px;
    height: 100px;
}

.waveTooltip {
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translate(-50%, -18px);
            transform: translate(-50%, -18px);
    text-align: center;
    z-index: 10;
    font-family: futura-pt, sans-serif;
    opacity: 0;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
    transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;
    white-space: nowrap;
    pointer-events: none;
}

.waveTooltip[data-visible=true] {
    opacity: 1.0;
    -webkit-transform: translate(-50%, -38px);
            transform: translate(-50%, -38px);
}

.waveTooltipText {
    background: #FB88F4;
    padding: 4px 12px;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.5px;
    line-height: 31px;
}

.waveTooltipLanguage {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.38px;
}

@media screen and (max-width: 990px) {
    .waveComponent canvas {
        width: 80vw;
        height: 80vw;
        max-width: 330px;
        max-height: 330px;
    }
}

footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #768ADE;
}

footer img {
    height: 20px;
    margin-right: 80px;
}

footer span {
    color: #FFFFFF;
    font-family: futura-pt, sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
}
.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container {
    min-height: calc(100vh - 63px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
}

.gridRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.gridItem {
    flex-basis: 0;
}

@media screen and (max-width: 990px) {
    .container {
        width: 100vw;
        flex-direction: column;
    }
    .overlay {
        position: relative;
        margin: 1rem 0;
    }
    .grid {
        justify-content: center;
    }
    .gridRow {
        flex-direction: column;
        justify-content: center;
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.smuLink {
    text-decoration: none;
    color: white;
}

.smuLink:hover {
    text-decoration: underline;
}

.headerSmall {
	color: #FFFFFF;
	font-family: futura-pt, sans-serif;
	font-size: 32px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 41px;
    text-transform: uppercase;
}

.headerPara {
	color: #FFFFFF;
	font-family: futura-pt, sans-serif;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
    line-height: 22px;
    max-width: 400px;
}

.headerBig {
	color: #FFFFFF;
	font-family: duos-paint, sans-serif;
    font-weight: 900;
    font-style: normal;
	font-size: 5rem;
    line-height: 90px;
    margin: 20px 0;
}

@media screen and (max-width: 990px) {
    .header {
        margin-right: 0;
    }

    .headerSmall {
        font-size: 18px;
    }

    .headerBig {
        font-size: 56px;
        margin: 0 0;
    }

    .headerPara {
        margin-bottom: 36px;
    }
}
