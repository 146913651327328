footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #768ADE;
}

footer img {
    height: 20px;
    margin-right: 80px;
}

footer span {
    color: #FFFFFF;
    font-family: futura-pt, sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
}