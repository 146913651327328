.waveComponent {
    position: relative;
}

.waveComponent canvas {
    width: 15vh;
    height: 15vh;
    cursor: pointer;
}

.waveComponent[data-star="true"] canvas {
    width: 100px;
    height: 100px;
}

.waveTooltip {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -18px);
    text-align: center;
    z-index: 10;
    font-family: futura-pt, sans-serif;
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
    white-space: nowrap;
    pointer-events: none;
}

.waveTooltip[data-visible=true] {
    opacity: 1.0;
    transform: translate(-50%, -38px);
}

.waveTooltipText {
    background: #FB88F4;
    padding: 4px 12px;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.5px;
    line-height: 31px;
}

.waveTooltipLanguage {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.38px;
}

@media screen and (max-width: 990px) {
    .waveComponent canvas {
        width: 80vw;
        height: 80vw;
        max-width: 330px;
        max-height: 330px;
    }
}
